<template>
  <v-app
    id="login"
    class="primary"
  >
    <Notification />
    <v-main>
      <v-container
        fluid
        fill-height
      >
        <v-layout
          align-center
          justify-center
        >
          <v-flex
            xs12
            sm8
            md4
            lg4
          >
            <v-card>
              <v-toolbar
                color="indigo darken-4"
                dark
                flat
                dense
              >
                <v-toolbar-title>
                  Forgot password
                </v-toolbar-title>
              </v-toolbar>
              <ValidationObserver
                v-slot="{ passes }"
                tag="form"
              >
                <v-card-text>
                  If you have forgotten your password, simply enter the email address below that you registered with.

                  <ValidationProvider
                    v-slot="{ errors, valid }"
                    name="Email"
                    rules="required|email"
                  >
                    <v-text-field
                      v-model="email"
                      :error-messages="errors"
                      :success="valid"
                      type="email"
                      label="Email"
                      prepend-icon="mdi-email"
                      required
                    />
                  </ValidationProvider>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    @click="passes(forgotPassword)"
                  >
                    Change password
                  </v-btn>
                </v-card-actions>
              </ValidationObserver>
            </v-card>
            <div class="row">
              <div class="col-6">
                <router-link
                  :to="{ name: 'dashboard.login' }"
                  class="text-light"
                >
                  <small>Login</small>
                </router-link>
              </div>
            </div>
            <v-dialog
              v-model="verificationDialog"
              persistent
              max-width="490"
            >
              <v-card>
                <v-card-title class="text-h5">
                  Reset Password
                </v-card-title>
                <v-card-text>
                  <ol>
                    <li>We emailed a password reset link to your email. Please follow the instructions in that email.</li>
                    <li>Check the inbox of the email address you use to sign in to your account.</li>
                    <li>Check your Spam or Bulk Mail folders. It's possible that the email we tried to send you was caught by a spam or bulk mail filter in your email system.</li>
                  </ol>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="primary darken-2"
                    text
                    :to="{ name: 'dashboard.login' }"
                  >
                    Go to login page
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Notification from '@/components/Notification'

export default {
  name: 'ForgotPassword',
  components: {
    Notification
  },
  data: () => ({
    email: null,
    verificationDialog: false
  }),
  methods: {
    forgotPassword () {
      this.$http.put('/reset_password', {
        email: this.email,
        route_update_password: process.env.VUE_APP_SERVER + 'update-password/:token/email/:email'
      }).then(() => {
        this.verificationDialog = true
      }).catch(error => {
        this.displayError(error)
      })
    }
  }
}
</script>

<style lang="css">
#login {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
}
</style>
