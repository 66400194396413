<template>
  <v-snackbar
    v-model="show"
    :color="color"
  >
    {{ message }}
    <template #action="{ attrs }">
      <v-btn
        color="black"
        text
        v-bind="attrs"
        @click="show = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data () {
    return {
      show: false,
      message: '',
      color: ''
    }
  },
  created () {
    this.$store.watch(state => state.notification.message, () => {
      const msg = this.$store.state.notification.message
      if (msg !== '') {
        this.show = true
        this.message = this.$store.state.notification.message
        this.color = this.$store.state.notification.color
        this.$store.commit('setNotification', { color: '', message: '' })
      }
    })
  }
}
</script>
